export var PARTNER_ID = "2";
export var CLIENT_ID = "ujaePi2haen6ioh4egaCoo8Jio6ii2ye";
export var ACQUIRING_API_BASE_URL = "https://api.charism.ai/acquiring";
export var APPROVER_API_BASE_URL = "https://api.charism.ai";
export var CARD_HOLDER_API_BASE_URL = "https://api.charism.ai";
export var CURRENCY_API_BASE_URL = "https://api.charism.ai/currency";
export var REGISTRATION_API_BASE_URL = "https://api.charism.ai/reg";
export var WALLET_API_BASE_URL = "https://api.charism.ai/wallet";
export var ZEN_DESK_ID = "6083da3b-a66c-4c6f-b8e4-789efa9f7e91";
export var ZEN_DESK_NAME = "Banqeta";
export var EMAIL_HELP = "help@charism.io";
export var EMAIL_HELLO = "hello@banqeta.com";
