var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@emotion/styled";
import BN from "bignumber.js";
import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
var roundToNearest5 = function (x) {
    var numb = Number(x);
    return Math.round(numb / 5) * 5;
};
export var SubscriptionCard = function (_a) {
    var options = _a.options, title = _a.title, fee = _a.fee, monthly = _a.monthly, discount = _a.discount, onSelect = _a.onSelect, _b = _a.theme, theme = _b === void 0 ? "white" : _b, disabled = _a.disabled;
    return (React.createElement(Box, { padding: "30px", display: "flex", flexDirection: "column", gap: "20px", bgColor: theme === "black" ? "black-1200" : "general-white", borderRadius: "22px" },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { type: "text24Bold", color: theme === "black" ? "general-white" : "black-1200" }, title)),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Box, { display: "flex", gap: 2, alignItems: "center" },
                    React.createElement(Typography, { type: "text36Bold", textDecoration: discount ? "line-through" : "unset", fontWeight: 500, color: discount
                            ? "black-500"
                            : theme === "black"
                                ? "general-white"
                                : "black-1200" },
                        "$",
                        fee),
                    discount && (React.createElement(Typography, { type: "text36Bold", color: theme === "black"
                            ? "general-white"
                            : "black-1200" },
                        "$",
                        roundToNearest5(new BN(fee)
                            .times(new BN(100 - discount).div(100))
                            .toFixed(0))))),
                discount && (React.createElement(Typography, { color: "general-green" },
                    discount,
                    "% referral discount")),
                React.createElement(Typography, { color: theme === "black" ? "general-white" : "black-1200" }, "one-time setup fee")),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Typography, { color: theme === "black" ? "general-white" : "black-1200" }, "monthly subscription"),
                React.createElement(Box, { display: "flex", gap: 2, alignItems: "center" },
                    React.createElement(Typography, { type: "text36Bold", color: theme === "black" ? "general-white" : "black-1200" },
                        "$",
                        monthly)),
                React.createElement(Typography, { color: theme === "white" ? "deep-blue-700" : "general-green" }, "First month FREE"))),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
            React.createElement(Button, { disabled: disabled || !onSelect, size: "s", variant: theme === "black" ? "White" : "Black", onClick: onSelect }, "Select plan"),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" }, options.map(function (option, index) { return (React.createElement(Box, { display: "flex", alignItems: "center", gap: "6px", 
                // eslint-disable-next-line react/no-array-index-key
                key: index },
                React.createElement(BaseIcon, { icon: "Check", color: theme === "black"
                        ? "general-white"
                        : "black-1200", size: 8 }),
                React.createElement(Typography, { color: theme === "black"
                        ? "general-white"
                        : "black-1200", type: "text14" },
                    React.createElement(Span, { dangerouslySetInnerHTML: {
                            __html: option,
                        } })))); })))));
};
export var Span = styled("span")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    b {\n        color: var(--deep-blue-700);\n    }\n"], ["\n    b {\n        color: var(--deep-blue-700);\n    }\n"])));
var templateObject_1;
